import Alpine from 'alpinejs'

// Keep track of loaded components
const loadedComponents = new Set<string>()
let isAlpineStarted = false

export function registerAlpineComponent(componentName: string, componentDefinition: () => any) {
  // Register the component with Alpine
  Alpine.data(componentName, componentDefinition)

  // Track that we've loaded this component
  loadedComponents.add(componentName)
}

export function startAlpineIfReady() {
  // Only start Alpine once
  if (!isAlpineStarted) {
    Alpine.start()
    isAlpineStarted = true
  }
}
