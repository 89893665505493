// Our main CSS
import '../css/app.css'
import { startAlpineIfReady } from './utils/alpineSetup'

/**
 * 🦄: Now do your magic.
 */

import initializeMobileMenu from './components/MobileMenu'
import LazyLoad from 'vanilla-lazyload'

initializeMobileMenu()

const lazyLoad = new LazyLoad({
  elements_selector: '.lazy',
  threshold: 25,
})

window.lazyload = lazyLoad

const anchorTags = document.querySelectorAll<HTMLAnchorElement>('a')
anchorTags.forEach((tag) => {
  if (tag.href === '#' || tag.getAttribute('href') === '#') {
    tag.href = 'javascript:;'
  }
})

const TextImageSliders = document.querySelectorAll<HTMLElement>('[data-js="TextImageSlider"]')
if (TextImageSliders && TextImageSliders !== null) {
  import('./components/TextImageSlider')
    .then((module) => {
      module.default(TextImageSliders as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const CardSliders = document.querySelectorAll<HTMLElement>('[data-js="CardSlider"]')
if (CardSliders && CardSliders !== null) {
  import('./components/CardSlider')
    .then((module) => {
      module.default(CardSliders as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AccordionTriggers = document.querySelectorAll<HTMLElement>('[data-js="AccordionTrigger"]')
if (AccordionTriggers && AccordionTriggers !== null) {
  import('./components/Accordion')
    .then((module) => {
      module.default(AccordionTriggers as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const Videos = document.querySelectorAll<HTMLElement>('[data-js="player"]')
if (Videos && Videos !== null) {
  import('./components/Video')
    .then((module) => {
      module.default(Videos as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const scrollToTop = document.querySelectorAll<HTMLElement>('[data-js="ScrollToTop"]')
scrollToTop?.forEach(function (el) {
  el.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
})

const NewsFilterContainer = document.getElementById('VueNewsFilterContainer')
if (NewsFilterContainer && NewsFilterContainer !== null) {
  import('./NewsFilter/index.ts')
    .then((module) => {
      module.default(NewsFilterContainer as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueContactForm = document.getElementById('VueContactForm')
if (VueContactForm && VueContactForm !== null) {
  import('./ContactForm/index.ts')
    .then((module) => {
      module.default(VueContactForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueNewsletterForm = document.getElementById('VueNewsletterForm')
if (VueNewsletterForm && VueNewsletterForm !== null) {
  import('./NewsletterForm/index.ts')
    .then((module) => {
      module.default(VueNewsletterForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueTrafficReportForm = document.getElementById('VueTrafficReportForm')
if (VueTrafficReportForm && VueTrafficReportForm !== null) {
  import('./TrafficReportForm/index.ts')
    .then((module) => {
      module.default(VueTrafficReportForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const searchButtonToggle = document.querySelector<HTMLElement>('[data-js="SearchButtonToggle"]')
if (searchButtonToggle && searchButtonToggle !== null) {
  const searchInput = document.querySelector<HTMLElement>('[data-js="SearchInput"]')
  searchButtonToggle.addEventListener('click', () => {
    searchInput?.classList.toggle('active')

    if (searchInput?.classList.contains('active')) {
      searchInput?.querySelector('input')?.focus()
    }
  })
}

const VueGroupBookingForm = document.getElementById('VueGroupBookingForm')
if (VueGroupBookingForm && VueGroupBookingForm !== null) {
  import('./GroupBookingForm/index.ts')
    .then((module) => {
      module.default(VueGroupBookingForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueBookingRequestForm = document.getElementById('VueBookingRequestForm')
if (VueBookingRequestForm && VueBookingRequestForm !== null) {
  import('./BookingRequestForm/index.ts')
    .then((module) => {
      module.default(VueBookingRequestForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const OverlayVideoButton = document.querySelectorAll<HTMLElement>('[data-js="ShowOverlayVideo"]')
OverlayVideoButton.forEach((element) => {
  element.addEventListener('click', () => {
    const overlay = document.querySelector<HTMLElement>('[data-js="VideoOverlay"]')
    overlay?.classList.replace('opacity-0', 'opacity-100')
    overlay?.classList.replace('pointer-events-none', 'pointer-events-auto')

    const videos = overlay?.querySelectorAll<HTMLElement>('[data-js="player"]')
    if (videos && videos !== null) {
      import('./components/Video')
        .then((module) => {
          module.default(videos as NodeListOf<HTMLElement>)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const closeOverlay = overlay?.querySelector<HTMLElement>('[data-js="CloseVideoOverlay"]')
    closeOverlay?.addEventListener('click', () => {
      overlay?.classList.replace('opacity-100', 'opacity-0')
      overlay?.classList.replace('pointer-events-auto', 'pointer-events-none')
    })
  })
})

// Cruise calendar filter
// const cruise_archive = document.querySelectorAll(
//   '.CruiseCalendarArchive'
// ) as NodeListOf<HTMLElement>
// if (cruise_archive?.length) {
//   cruise_archive.forEach(async (item) => {
//     await import('./components/CruiseFilter').then((module) => {
//       module.default(item as HTMLElement)
//     })
//   })
// }

// const cruise_calendar_picker = document.querySelectorAll(
//   '[data-cruise-calendar-picker]'
// ) as NodeListOf<HTMLElement>
// if (cruise_calendar_picker?.length) {
//   cruise_calendar_picker.forEach(async (item) => {
//     await import('./components/CruiseDatePicker').then((module) => {
//       module.default(item as HTMLElement)
//     })
//   })
// }
//
//
//

const componentsToLoad = []

// Check for cruise filter elements
const cruiseArchiveElements = document.querySelectorAll('.CruiseCalendarArchive')
if (cruiseArchiveElements.length) {
  componentsToLoad.push(import('./components/CruiseFilter').then((module) => module.default()))
}

// Check for cruise calendar picker elements
const cruiseCalendarPickers = document.querySelectorAll('[data-cruise-calendar-picker]')
if (cruiseCalendarPickers.length) {
  componentsToLoad.push(import('./components/CruiseDatePicker').then((module) => module.default()))
}

// If any components need to be loaded, load them and then start Alpine
if (componentsToLoad.length) {
  Promise.all(componentsToLoad)
    .then(() => {
      startAlpineIfReady()
    })
    .catch((error) => {
      console.error('Error loading Alpine components:', error)
    })
}

// Cruise calendar filter
const TestimonialSliders = document.querySelectorAll<HTMLElement>(
  '[data-swiper-slider="TestimonialsSlider"]'
)
if (TestimonialSliders && TestimonialSliders !== null) {
  import('./components/TestimonialSliders')
    .then((module) => {
      module.default(TestimonialSliders as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}
